import { springboardApiRequest } from '../SpringboardApiHandler';
import endpoints from '../endpoints';
import apiMessages from '../apiMessages';


const API_FEEDBACK_TIMEOUT = 5000;

class ApiRequestManager {
	static instance;
	requestId = 0;
	requestQueue = [];
	subscribers = new Set();

	constructor() {
		if (ApiRequestManager.instance) {
			return ApiRequestManager.instance;
		}
		ApiRequestManager.instance = this;
	}

	subscribe(callback) {
		console.log("NEW SUB for api");
		this.subscribers.add(callback);
		return () => {
			this.subscribers.delete(callback);
		};
	}

	notifySubscribers() {
		console.log("Notifying subs of api req");
		this.subscribers.forEach(callback => callback([...this.requestQueue]));
	}

	addRequestToQueue(functionName) {
		const newRequest = {
			id: this.requestId++,
			apiFunctionName: functionName,
			loading: true,
			success: false,
			progress: 0,
			message: ''
		};
		this.requestQueue.push(newRequest);
		this.notifySubscribers();
		return newRequest.id;
	}

	removeRequestFromQueue(requestId) {
		this.requestQueue = this.requestQueue.filter(request => request.id !== requestId);
		this.notifySubscribers();
	}

	updateProgressInQueue(requestId, progress) {
		const request = this.requestQueue.find(r => r.id === requestId);
		if (request) {
			request.progress = progress;
			this.notifySubscribers();
		}
	}

	updateRequestInQueue(requestId, updatedRequest) {
		console.log("UPDATE QUEUE:", this.requestQueue);
		const request = this.requestQueue.find(r => r.id === requestId);
		if (request) {
			Object.assign(request, updatedRequest);
			this.notifySubscribers();
			this.setAutoRemoveRequest(requestId);
		}
	}

	setAutoRemoveRequest(requestId) {
		setTimeout(() => {
			this.removeRequestFromQueue(requestId);
		}, API_FEEDBACK_TIMEOUT);
	}

	setRequestSuccess(requestId, message = 'Success message') {
		this.updateRequestInQueue(requestId, {
			loading: false,
			success: true,
			message: message
		});
	}

	setRequestFailure(requestId, message = 'Error occurred') {
		this.updateRequestInQueue(requestId, {
			loading: false,
			success: false,
			message: message
		});
	}

	async withReqManager(apiCall, apiMsg) {
		const requestId = this.addRequestToQueue(apiMsg.Type || "POST");
		try {
			const response = await apiCall();
			console.log("DATA RES:", response);
			this.setRequestSuccess(requestId, apiMsg.success);
			return response;
		} catch (error) {
			console.error(`ERROR, ${apiMsg.error}: ${error.message}`);
			this.setRequestFailure(requestId, apiMsg.error);
			throw error;
		}
	};

	async managePostRequest(endpoint, action, data) {
		let req = () => springboardApiRequest(endpoints[endpoint][action](), "POST", data);
		let result = await this.withReqManager(req, apiMessages[endpoint][action]);
		return result.data;
	}
}

const apiRequestManager = new ApiRequestManager();
export default apiRequestManager;