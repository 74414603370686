

export function normaliseLOIData(loiData) {
	return loiData.map(item => {
		let newItem = { ...item };

		// Uppercase the main Id
		if (newItem.Id) {
			newItem.Id = newItem.Id.toUpperCase();
		}

		// Uppercase the AttributeId
		if (newItem.AttributeId) {
			newItem.AttributeId = newItem.AttributeId.toUpperCase();
		}

		// Uppercase the UserId inside LastSaved, if it exists
		if (newItem.LastSaved && newItem.LastSaved.UserId) {
			newItem.LastSaved.UserId = newItem.LastSaved.UserId.toUpperCase();
		}

		return newItem;
	});
}

export function normaliseLOIAttributes(loiAttrs) {
	return loiAttrs.map(item => {
		let newItem = { ...item };

		// Uppercase the Id
		if (newItem.Id) {
			newItem.Id = newItem.Id.toUpperCase();
		}

		// Uppercase the attrFullId
		if (newItem.attrFullId) {
			newItem.attrFullId = newItem.attrFullId.toUpperCase();
		}

		return newItem;
	});
}

export function normaliseLOIClassifications(loiClassifications) {
	return loiClassifications.map(item => {
		let newItem = { ...item };

		// Uppercase the Id
		if (newItem.Id) {
			newItem.Id = newItem.Id.toUpperCase();
		}

		return newItem;
	});
}

// Changes the recieved data config into a format suitable for this application.
export function normaliseLOIMaskedDataConfig(loiDataConfig) {
	const transformedDataConfig = {};

	loiDataConfig.forEach(config => {
		transformedDataConfig[config.sheetName] = Object.values(config.columns).map(value => ({
			id: value,
			name: value
		}));
	})

	console.log("Masked data list: ", transformedDataConfig);

	return transformedDataConfig;
}

export function convertLOINamesToSelectList(loiNames) {
	return loiNames.map((loiName) => {
		return {
			id: loiName.loiName,
			name: loiName.loiName
		}
	});
}

export function convertLOINamesToSITESelectList(loiNames) {
	// We only want LOINames that are relevant to SITE level
	// As project LOIs have a 1 to 1 relationship with its LOI.
	const filteredLoiNames = loiNames.filter(item =>
		item.projects.length === 0 || // Include items with no projects
		item.projects.some(project => !project.isProjectLevelLOI) // Include items that are not project level loi's
	).map(item => item.loiName);

	return filteredLoiNames.map((loiName) => {
		return {
			id: loiName,
			name: loiName
		}
	});
}

export function convertLOIClassificationsToDict(loiClassifications) {
	return loiClassifications.reduce((acc, attr) => {
		// Use 'SITE' as key if projectId is null
		let key = attr.projectId || 'SITE';

		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push(attr);
		return acc;
	}, {});
}

export function convertLOIAttributesToDict(loiAttributes) {
	/*
	return loiAttributes.reduce((acc, item) => {
		let key = item.loiName;

		if (!acc[key]) {
			acc[key] = [];
		}

		acc[key].push(item);

		return acc;
	}, {});
	*/
	let allUniqueItemsMap = new Map();
	let attrDict = loiAttributes.reduce((acc, item) => {
		let key = item.loiName;

		// In the unique case of this attribute not having an order, set it to a default.
		let tempForceOrder = item.forceOrder;
		//let colOrder = item.default.sortOrder; // OLD Using default sort?
		let colOrder = item.rowOrder;
		if (tempForceOrder && tempForceOrder > 0) {

			colOrder = tempForceOrder;
		}

		item.forceOrder = colOrder;

		// Ensure display names are unique.
		item.displayName = item.commonName;
		if (item.commonName !== item.name) {
			item.displayName = item.displayName + "." + item.name;
		}

		// For all attributes which have no loiName yet..
		if (key === null) {
			if (!allUniqueItemsMap.has(item.id)) {
				allUniqueItemsMap.set(item.id, item);
			}
			return acc;
		}

		// Initialize the group if it hasn't been seen before
		if (!acc[key]) {
			acc[key] = {
				items: [],
				seenIds: new Set()
			};
		}

		// Add the item to the group if its id hasn't been seen before in this group
		if (!acc[key].seenIds.has(item.id)) {
			acc[key].seenIds.add(item.id);
			acc[key].items.push(item);
		}

		return acc;
	}, {});

	// Convert the attributes into a flat array containing all unique items across all groups
	Object.values(attrDict).forEach(group => {
		group.items.forEach(item => {
			if (!allUniqueItemsMap.has(item.id)) {
				allUniqueItemsMap.set(item.id, item);
			}
		});
	});

	let allUniqueItems = Array.from(allUniqueItemsMap.values());

	console.log("Number of unique Attributes:", allUniqueItems.length);

	for (let key in attrDict) {
		// Ensure each group contains all unique items, adding missing ones
		const group = attrDict[key];
		allUniqueItems.forEach(item => {
			if (!group.seenIds.has(item.id)) {
				group.items.push(item);
			}
		});

		group.items.sort((a, b) => {
			/*if (a.structureOrder !== b.structureOrder) {
				return a.structureOrder - b.structureOrder;
			}*/
			return a.forceOrder - b.forceOrder;
		});

		// Clean up to leave just the items array
		attrDict[key] = group.items;
	}

	return attrDict;
}

export function convertLOIToServerFormat(loiData) {
	return loiData.map(item => {
		return {
			Id: item.id,
			CommonName: item.classification,
			AttributeId: item.attributeId,
			IsEnabled: item.enabled,
			Stage: item.stage,
			IsDisplayed: item.displayed,
			IsValidationRequired: item.required
		};
	});
}