import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import {
	LOIContext,
	ProjectContext,
	SelectedLOIDataContext,
	SiteSecurityContext,
	SiteDataContext
} from '../../../contexts';
import { AgGridLOIView } from '../../DataGridContainers';
import {
	ApiStatusDisplay,
	SelectDropdown,
	SecurityButton,
	LoadingSpinner
} from '../../ui';
import { LoadingPage } from '..';
import { isObjectEmpty } from '../../../helpers/GeneralHelpers';
import * as LOIGridHelpers from '../../../helpers/AgGrid/LOIGridHelpers';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';
import BulkActionManager from './components/BulkActionManager';
import AdvancedLOIModal from './components/AdvancedLOIModal';


// TODO:
// Fix issue with DataLoading - this only counts the loading of the 'loiData' and not
// the classifications etc...
// Fix 'bugs' suggested by dan/luke
//  - Add set classification name (structural name)
//  - fix minor visual bug when selecting certain attrs where max length text field morphs into the data?

const SITE_LOI = {
	id: 'SITE',
	name: 'SITE'
};

const LevelsOfInformationPage = () => {
	const {
		loiNames,
		loiNamesSiteSelectList,
		loiByName,
		loiAttributesByLoiName,
		loiClassificationsByProjectId,
		loadLOITableSchema,
		loadLOIData,
		loadLOINames,
		updateAttrOrder
	} = useContext(LOIContext);

	const {
		loadStructuralNames
	} = useContext(SiteDataContext);
	const { projects } = useContext(ProjectContext);
	const { isFeatureAllowed } = useContext(SiteSecurityContext);
	const [selectedProjectId, setSelectedProjectId] = useState("");
	const [selectedLOI, setLOI] = useState("");
	const [isProjectLOI, setIsProjectLOI] = useState(false);
	const [dataLoaded, setDataloaded] = useState(false);
	const [dataLoading, setDataLoading] = useState(false);
	const [updatedColOrder, setUpdatedColOrder] = useState({});
	const [openAdvancedSettings, setOpenAdvancedSettings] = useState(false);

	const featureAllowed = useMemo(() => {
		const featureName = 'LOI_create_update_delete';
		return isFeatureAllowed(featureName);
	}, [isFeatureAllowed]);

	useEffect(() => {
		loadLOITableSchema();
		loadLOINames();
	}, [loadLOINames, loadLOITableSchema]);

	const selectLOI = async (loiName) => {
		setDataloaded(false);
		setDataLoading(true);
		setLOI(loiName);
		await loadStructuralNames();
		setDataloaded(await loadLOIData(loiName));
		setDataLoading(false);
	}

	const handleSelectProject = (projectId) => {
		console.log("Project :", projectId, " Selected");
		setSelectedProjectId(projectId);
		if (projectId === SITE_LOI.id) {
			if (loiNamesSiteSelectList.length === 0) {
				setLOI("");
			} else {
				setLOI(loiNamesSiteSelectList.length > 1 ? "" : loiNamesSiteSelectList[0].id);
			}
			return;
		}

		const loiName = LOIGridHelpers.findLOINameByProjectId(loiNames, projectId);
		if (loiName)
		{
			setIsProjectLOI(loiName.isProjectLevelLOI);
			selectLOI(loiName.name);
		}
	}

	const handleSetAdvancedLOI = (loiName) => {
		if (selectedProjectId === "") {
			setSelectedProjectId(SITE_LOI.id);
		}
		selectLOI(loiName);
	}

	const projectListLoi = useMemo(() => {
		const projectIds = [];
		// Only display projects that have a specific LOI
		loiNames.forEach(item => {
			const filteredProjects = item.projects.filter(project => project.isProjectLevelLOI);
			const filteredIds = filteredProjects.map(project => project.id);
			projectIds.push(...filteredIds);
		});

		const filteredProjects = projects.filter(project => projectIds.includes(project.id));

		// If site has no project specific LOIs, simply default to SITE selected
		if (filteredProjects.length === 0) {
			setSelectedProjectId(SITE_LOI.id);
		}

		return [...filteredProjects, SITE_LOI];
	}, [loiNames, projects]);

	const handleSetNewColOrder = useCallback((changedCols) => {
		setUpdatedColOrder(changedCols);
		console.log("Changes:", changedCols);
	}, []);

	const handleSaveAttrOrder = () => {
		updateAttrOrder(updatedColOrder, selectedLOI);
	}

	const selectedLOIContextValue = {
		selectedLOI: selectedLOI,
		loiData: loiByName[selectedLOI],
		loiAttributes: loiAttributesByLoiName[selectedLOI]
	};

	const hasLOILoaded = !isObjectEmpty(loiNames);
	if (!hasLOILoaded) {
		return <LoadingPage />;
	}
	const isAttrOrderUnchanged = isObjectEmpty(updatedColOrder);

	return (
		<Grid container spacing={1}>
			<Grid item xs={3}>
				<SelectDropdown
					id={"project"}
					value={selectedProjectId}
					onChange={handleSelectProject}
					options={projectListLoi}
					fullWidth
				/>
			</Grid>
			<Grid item xs={3}>
				{selectedProjectId === SITE_LOI.id ? (
					<SelectDropdown
						id={"LOI"}
						value={selectedLOI}
						options={loiNamesSiteSelectList}
						onChange={selectLOI}
						fullWidth
					/>
				) : (
					<Typography>
						LOI: {selectedLOI}
					</Typography>
				)}
			</Grid>
			<Grid item xs={3}>
				<Button
					onClick={handleSaveAttrOrder}
					variant="contained"
					color={"primary"}
					disabled={isAttrOrderUnchanged}
					autoFocus>
					Save Attribute Order
				</Button>
			</Grid>
			<Grid item xs={3}>
				<SecurityButton
					onClick={() => setOpenAdvancedSettings(true)}
					variant="contained"
					color={"primary"}
					featureName={SECURITY_FEATURES.ADMIN_ONLY}>
					Advanced
				</SecurityButton>
				<AdvancedLOIModal
					open={openAdvancedSettings}
					onClose={() => setOpenAdvancedSettings(false)}
					setSelectedLOI={handleSetAdvancedLOI}
					selectedLOI={selectedLOI}
				/>
			</Grid>
			<Grid item container xs={12}>
				<ApiStatusDisplay />
			</Grid>
			<Grid item xs={12}>
				{dataLoaded && (
					<SelectedLOIDataContext.Provider value={selectedLOIContextValue} >
						<AgGridLOIView
							selectedLOI={selectedLOI}
							loiData={loiByName[selectedLOI]}
							loiAttributes={loiAttributesByLoiName[selectedLOI]}
							loiClassifications={loiClassificationsByProjectId[selectedProjectId]}
							updateColumnOrder={handleSetNewColOrder}
							isProjectLOI={isProjectLOI}
							featureAllowed={featureAllowed}
						/>
					</SelectedLOIDataContext.Provider>
				)}
				<LoadingSpinner isLoading={dataLoading} />
			</Grid>
			<Grid item xs={12}>
				<BulkActionManager
					display={dataLoaded}
					selectedLOI={selectedLOI}
					selectedProjectId={selectedProjectId}
					featureAllowed={featureAllowed}
				/>
			</Grid>
		</Grid>
	);
}

export default LevelsOfInformationPage;