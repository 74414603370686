import { getBaseURL, getSiteId } from './configManager';

const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;

function createAPIUrl(endpoint, params = {}) {
	const query = Object.keys(params)
		.map(key => {
			// Used for passing in param groups with the same values, such as chunking data
			// i.e. load users
			if (Array.isArray(params[key])) {
				return params[key].map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
			}
			// Used for passing in normal params
			return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
		})
		.join('&');
	return `${getBaseURL()}${apiEndPoint}${endpoint}?${query}`;
}

const endpoints = {
	users: {
		search: () => `${getBaseURL()}${apiEndPoint}Users/SearchUsers`,
		rolesBySite: (siteId) => `${getBaseURL()}${apiEndPoint}SiteManagement/RolesBySite?siteID=${siteId}`,
		byIds: (userIds) => createAPIUrl('Users/ByIds', { ids: userIds }),
		create: () => `${getBaseURL()}${apiEndPoint}Users/CreateUsers`,
		whoAmI: () => `${getBaseURL()}${apiEndPoint}Users/WhoAmI`,
	},
	companies: {
		byIds: () => createAPIUrl("Companies/ByIds"),
		searchByName: (name) => createAPIUrl("Companies/search", { name })
	},
	projects: {
		bySiteId: (siteId) => `${getBaseURL()}${apiEndPoint}Projects/BySiteId?siteID=${siteId}`,
		byProjectId: (id) => `${getBaseURL()}${apiEndPoint}Projects/ById?Id=${id}`,
		delete: (id) => `${getBaseURL()}${apiEndPoint}Projects/Delete?Id=${id}`,
		create: () => `${getBaseURL()}${apiEndPoint}Projects/Create`,
		update: () => `${getBaseURL()}${apiEndPoint}Projects/Update`,
	},
	projectClassifications: {
		getByProjectId: (projectId) => `${getBaseURL()}${apiEndPoint}ProjectClassification/classificationConfigByProjectId?projectId=${projectId}`,
		update: () => `${getBaseURL()}${apiEndPoint}ProjectClassification/updateProjectClassificationConfig`,
		create: () => `${getBaseURL()}${apiEndPoint}ProjectClassification/createProjectClassificationConfig`,
		delete: (id) => `${getBaseURL()}${apiEndPoint}ProjectClassification/deleteProjectClassificationConfig?id=${id}`,
		getCodesByGroupId: (groupId) => createAPIUrl('ProjectClassification/GetCodesByGroupId', { groupId }),
	},
	documentTeamMembers: {
		byProjectId: (projectId) => `${getBaseURL()}${apiEndPoint}DocumentTeamMembers/ByProjectId?projectId=${projectId}`,
		addRoles: () => `${getBaseURL()}${apiEndPoint}DocumentTeamMembers/AddRoles`,
		removeRoles: () => `${getBaseURL()}${apiEndPoint}DocumentTeamMembers/RemoveRoles`,
		setAccessExpiration: () => `${getBaseURL()}${apiEndPoint}DocumentTeamMembers/SetAccessExpriation`,
		setRoleDescription: () => `${getBaseURL()}${apiEndPoint}DocumentTeamMembers/SetRoleDescription`,
		removeAccess: () => `${getBaseURL()}${apiEndPoint}DocumentTeamMembers/RemoveAccess`,
		getMySecurity: () => `${getBaseURL()}${apiEndPoint}DocumentTeamMembers/GetMySecurity`,
	},
	loi: {
		namesBySiteId: (siteId) => createAPIUrl('LOI/LOINamesBySiteId', { siteID: siteId }),
		getAttributesFull: (siteId) => createAPIUrl('LOI/GetAttributesFull', { siteID: siteId }),
		getClassifications: (siteId) => createAPIUrl('LOI/GetClassifications', { siteID: siteId }),
		getLOIByName: (loiName) => createAPIUrl('LOI/GetLOIByName', { loiName }),
		maskedDataConfig: (loiName) => createAPIUrl('LOI/maskedDataConfig', { loiName }),
		// Below gives data in different format: Perhaps temporary for above?
		getMaskedDataConfig: (loiName) => createAPIUrl('LOI/maskedDataConfiguration', { loiName }),
		loiImportOptions: (projectId) => createAPIUrl('LOI/loiImportOptions', { projectId }),
		bulkEdit: () => createAPIUrl('LOI/BulkEdit'),
		attributeConfigUpdate: () => createAPIUrl('LOI/attributeConfigUpdate'),
		updateAttributeOrder: () => createAPIUrl('LOI/UpdateAttributeOrder'),
		importLOI: () => createAPIUrl('LOI/importLOI'),
		downloadLOI: () => createAPIUrl('LOI/downloadLOI'),
		deleteLOI: (loiName) => createAPIUrl('LOI/deleteLOI', { loiName }),
		updateSchedule: () => createAPIUrl('LOI/updateSchedulesDueToMatchLOI'),
		copyLOI: () => createAPIUrl('LOI/copyLOI'),
	},
	workflows: {
		getResponsibilitiesByDocId: (docId) => `${getBaseURL()}${apiEndPoint}Actions/getResponsibilities?documentId=${docId}`,
		getSectionsByDocId: (docId) => `${getBaseURL()}${apiEndPoint}Sections/reviewSections?documentId=${docId}`,
		getWorkflowsById: (id) => `${getBaseURL()}${apiEndPoint}Workflows/ById?id=${id}`,
		getWorkflowsByDocId: (docId) => `${getBaseURL()}${apiEndPoint}Workflows/ByDocumentId?id=${docId}`,
		getReviewersByDocId: (docId) => `${getBaseURL()}${apiEndPoint}Actions/getReviews?documentId=${docId}`,
		getWorkflowsBySiteId: (siteId) => `${getBaseURL()}${apiEndPoint}Workflows/BySiteId?siteId=${siteId}`,
		calculateGivenStartDate: (dateDue, stepId, documentId) => createAPIUrl('Workflows/calculateGivenStartDate', { dateDue, stepId, documentId }),
		createReviewers: () => createAPIUrl('Actions/CreateReviewers'),
		createResponsibleUsers: () => createAPIUrl('Actions/CreateResponsibility'),
		clearResponsibleUsers: () => createAPIUrl('Actions/ClearResponsibility'),
		removeReviewers: () => createAPIUrl('Actions/RemoveReviewers'),
		create: () => `${getBaseURL()}${apiEndPoint}Workflows/Create`,
		update: () => `${getBaseURL()}${apiEndPoint}Workflows/Update`,
		delete: (id) => `${getBaseURL()}${apiEndPoint}Workflows/Delete?id=${id}`,
		duplicate: () => `${getBaseURL()}${apiEndPoint}Workflows/Duplicate`,
	},
	workflowSteps: {
		create: () => `${getBaseURL()}${apiEndPoint}WorkflowSteps/Create`,
		update: () => `${getBaseURL()}${apiEndPoint}WorkflowSteps/Update`,
		delete: (id) => `${getBaseURL()}${apiEndPoint}WorkflowSteps/Delete?id=${id}`,
	},
	workflowSections: {
		getSectionTreeByDocId: (documentId) => createAPIUrl('Sections/sectionsTree', { documentId }),
		setReviewSections: () => createAPIUrl('Sections/SetreviewSections'),
		setSectionsStartDate: () => createAPIUrl('Sections/SetSectionsStartDate'),
		setSectionsContractor: () => createAPIUrl('Sections/SetContractor'),
	},
	site: {
		getMySites: () => `${getBaseURL()}${apiEndPoint}Sites/getMySites`,
		getById: (id) => createAPIUrl('Sites/byId', { id }),
		update: () => createAPIUrl('Sites/update'),
	},
	file: {
		upload: () => `${getBaseURL()}${apiEndPoint}Files/Upload`,
		processSiteImage: () => `${getBaseURL()}${apiEndPoint}Files/processSiteImage`,
		processImageMapImage: () => `${getBaseURL()}${apiEndPoint}Files/processSiteImage`,
		processSiteBrandingImage: () => `${getBaseURL()}${apiEndPoint}Files/processSiteImage`,
		deleteSiteImages: () => `${getBaseURL()}${apiEndPoint}Files/DeleteSiteImage`,
		deleteImageMapImage: () => `${getBaseURL()}${apiEndPoint}Files/DeleteSiteImage`,
		processDataMaskLookupFile: () => `${getBaseURL()}${apiEndPoint}Files/processDataMaskLookup`,
	},
	platformConfig: {
		getStructuralNames: () => `${getBaseURL()}${apiEndPoint}PlatformConfig/StructualNames`,
		getLinkTypes: () => `${getBaseURL()}${apiEndPoint}PlatformConfig/LinkTypes`,
	},
	siteConfig: {
		getAllClassifications: () => `${getBaseURL()}${apiEndPoint}SiteConfig/getAllAvailableClassificationsBySiteId`,
		getAllTemplates: () => `${getBaseURL()}${apiEndPoint}SiteConfig/getAllAvailableTemplatesBySiteId`,
		saveProjectConfigAsTemplate: () => `${getBaseURL()}${apiEndPoint}SiteConfig/saveProjectConfigAsTemplate`,
		applyProjectClassificationConfig: () => `${getBaseURL()}${apiEndPoint}SiteConfig/applyProjectClassifactionConfig`,
	},
	siteLocations: {
		getRootLocations: () => `${getBaseURL()}${apiEndPoint}SiteLocations/getRootLocations`,
		addLocation: () => `${getBaseURL()}${apiEndPoint}SiteLocations/addLocation`,
		updateLocation: () => `${getBaseURL()}${apiEndPoint}SiteLocations/updateLocation`,
		moveLocation: () => `${getBaseURL()}${apiEndPoint}SiteLocations/moveLocation`,
		updateImageMapPoints: () => `${getBaseURL()}${apiEndPoint}SiteLocations/updateImageMapPoints`,
		deleteBranch: () => `${getBaseURL()}${apiEndPoint}SiteLocations/deleteBranch`,
		deleteSiteImage: () => `${getBaseURL()}${apiEndPoint}Files/deleteSiteImage`,
		createQuickLink: () => `${getBaseURL()}${apiEndPoint}SiteLocationsQuickLinks/Create`,
		updateQuickLink: () => `${getBaseURL()}${apiEndPoint}SiteLocationsQuickLinks/Update`,
		deleteQuickLink: () => `${getBaseURL()}${apiEndPoint}SiteLocationsQuickLinks/Delete`,
		searchLatlong: (requestOptions) => createAPIUrl(`MapService/SearchLatlong`, requestOptions),
	},
	nodeLocations: {
		Floor: {
			get: (projectId) => `${getBaseURL()}${apiEndPoint}Floors/getFloors?projectId=${projectId}`,
			create: () => `${getBaseURL()}${apiEndPoint}Floors/createFloor`,
			update: () => `${getBaseURL()}${apiEndPoint}Floors/updateFloor`,
			delete: () => `${getBaseURL()}${apiEndPoint}Floors/deleteFloors`,
		},
		Zone: {
			get: (projectId) => `${getBaseURL()}${apiEndPoint}Zones/getZones?projectId=${projectId}`,
			create: () => `${getBaseURL()}${apiEndPoint}Zones/createZone`,
			update: () => `${getBaseURL()}${apiEndPoint}Zones/updateZone`,
			delete: () => `${getBaseURL()}${apiEndPoint}Zones/deleteZones`,
		},
		Facility: {
			get: (projectId) => `${getBaseURL()}${apiEndPoint}Facilities/getFacilities?projectId=${projectId}`,
			create: () => `${getBaseURL()}${apiEndPoint}Facilities/createFacility`,
			update: () => `${getBaseURL()}${apiEndPoint}Facilities/updateFacility`,
			delete: () => `${getBaseURL()}${apiEndPoint}Facilities/deleteFacilities`,
		},
		Space: {
			get: (projectId) => `${getBaseURL()}${apiEndPoint}Spaces/getSpaces?projectId=${projectId}`,
			create: () => `${getBaseURL()}${apiEndPoint}Spaces/CreateSpace`,
			update: () => `${getBaseURL()}${apiEndPoint}Spaces/UpdateSpace`,
			delete: () => `${getBaseURL()}${apiEndPoint}Spaces/DeleteSpaces`,
		},
	},
	documents: {
		bySiteId: () => `${getBaseURL()}${apiEndPoint}Documents/BySiteId`,
		byProjectId: (projectId) => `${getBaseURL()}${apiEndPoint}Documents/ByProjectId?projectId=${projectId}`,
		byId: (id) => `${getBaseURL()}${apiEndPoint}Documents/ById?id=${id}`,
		getAllowedResponsibleUsersByDocId: (documentId) => `${getBaseURL()}${apiEndPoint}Actions/GetAllowedResponsibleUsers?documentId=${documentId}`,
		getMasterContentDocs: () => `${getBaseURL()}${apiEndPoint}Documents/MasterContentDocuments`,
		create: () => `${getBaseURL()}${apiEndPoint}Documents/Create`,
		update: () => `${getBaseURL()}${apiEndPoint}Documents/Update`,
		delete: (id) => `${getBaseURL()}${apiEndPoint}Documents/Delete?id=${id}`,
	},
	fileCategories: {
		bySideId: () => createAPIUrl('FileCategorys/BySiteId'),
		create: () => createAPIUrl('FileCategorys/Create'),
		update: () => createAPIUrl('FileCategorys/Update'),
	},
	fileTags: {
		create: () => createAPIUrl('FileTags/Create'),
		update: () => createAPIUrl('FileTags/Update'),
	},
	reporting: {
		getUserRoleChanges: (requestOptions) => createAPIUrl('Reporting/getUserRoleChanges', requestOptions)
	},
	goldenThread: {
		getLogs: (requestOptions) => createAPIUrl('GoldenThread/GetLogs', requestOptions)
	},
	siteManagement: {
		getSecurityRolesBySiteId: () => createAPIUrl('SiteManagement/GetSecurityRolesBySiteId'),
		getGlobalSecurity: () => createAPIUrl('SiteManagement/GetGlobalSecurity'),
		getUniclassVersions: () => createAPIUrl('SiteManagement/getUniclassVersions'),
		createSiteSecurityRole: () => createAPIUrl('SiteManagement/CreateSiteSecurityRole'),
		updateSiteSecurityRole: () => createAPIUrl('SiteManagement/UpdateSiteSecurityRole'),
		deleteSiteSecurityRole: (id) => `${getBaseURL()}${apiEndPoint}SiteManagement/DeleteSiteSecurityRole?id=${id}`,
		updateSiteSecurityRoleMaxtrix: () => createAPIUrl('SiteManagement/UpdateSiteSecurityRoleMaxtrix'),
		exportSecurity: () => createAPIUrl('SiteManagement/ExportSecurity'),
	},
	admins: {
		getAdminsBySiteId: (siteId) => `${getBaseURL()}${apiEndPoint}Admins/BySiteId?siteId=${siteId}`,
		create: () => `${getBaseURL()}${apiEndPoint}Admins/Create`,
		delete: () => `${getBaseURL()}${apiEndPoint}Admins/Delete`,
		swapMaster: () => `${getBaseURL()}${apiEndPoint}Admins/SwapMaster`,
	},
	help: {
		byTitle: (title) => `${getBaseURL()}${apiEndPoint}Help/get?title=${title}`,
		byTitles: () => `${getBaseURL()}${apiEndPoint}Help/GetBulk`,
	},
	sharedLinks: {
		getSharedLinksByDocumentId: (documentId) => `${getBaseURL()}${apiEndPoint}SharedLinks/ByDocumentId?id=${documentId}`,
		deleteSharedLinkById: (id) => `${getBaseURL()}${apiEndPoint}SharedLinks/Delete?id=${id}`,
		updateSharedLink: () => `${getBaseURL()}${apiEndPoint}SharedLinks/Update`,
		shareLinkWithUsers: () => `${getBaseURL()}${apiEndPoint}SharedLinks/ShareLinkWithUsers`
	}
};

export default endpoints;