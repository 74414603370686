import { DEV_TEST_MODE, loadJsonFile, downloadJsonData, getFakeQueueIdObj } from './devApi';
import apiRequestManager from '../Services/ApiRequestManager';
import { chunkArray } from '../../helpers/GeneralHelpers';
import { bulkUpdateLoiCellAdaptor } from '../DevelopmentDataAdapters/DevLOIAdapters';
import { getSiteId } from '../configManager';
import { springboardApiRequest } from '../SpringboardApiHandler';
import endpoints from '../endpoints';


// ############## Project LOIs: ########################

export async function loadLOINames(siteId = null) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('LOINamesBySiteId.json');
		}

		if (siteId == null)
			siteId = getSiteId();

		const getLoiNamesReq = endpoints.loi.namesBySiteId(siteId);
		let data = await springboardApiRequest(getLoiNamesReq, "GET");
		console.log("LOINAME DATA:", data);
		downloadJsonData(data.data, "LOINamesBySiteId");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching loi names data:', error);
		throw new Error('Failed to fetch loi names data.');
	}
}

export async function loadLOIAttributes() {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('get-attributes-full.json');
		}

		const attrsEndpoint = endpoints.loi.getAttributesFull(getSiteId());
		let data = await springboardApiRequest(attrsEndpoint, "GET");
		console.log("ATTR DATA:", data);
		downloadJsonData(data.data, "get-attributes-full");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching loi attributes data:', error);
		throw new Error('Failed to fetch loi attributes data.');
	}
}

export async function loadLOIClassifications() {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('get-classifications.json');
		}
		const classificationsEndpoint = endpoints.loi.getClassifications(getSiteId());
		let data = await springboardApiRequest(classificationsEndpoint, "GET");
		console.log("CLASS DATA:", data);
		downloadJsonData(data.data, "get-classifications");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching loi classifications data:', error);
		throw new Error('Failed to fetch loi classifications data.');
	}
}

export async function loadLOIData(loiName) {
	try {
		console.log("LOADING LOI DATA!");
		if (DEV_TEST_MODE) {
			// ONLY WORKS WITH 'DEFAULT' LOI
			return loadJsonFile('GetLOIByName.json');
		}
		const loiEndPoint = endpoints.loi.getLOIByName(loiName);
		console.log("loi endpoint:", loiEndPoint);
		let data = await springboardApiRequest(loiEndPoint, "GET");
		console.log("LOI DATA:", data);
		downloadJsonData(data.data, "GetLOIByName_" + loiName);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching loi data:', error);
		throw new Error('Failed to fetch loi data.');
	}
}

export async function getLoiMaskedDataConfig(loiName) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('getLoiMaskedDataConfig.json');
		}
		console.log("Get Masked LOI DATA!", loiName);
		let data = await springboardApiRequest(endpoints.loi.getMaskedDataConfig(loiName), "GET");
		console.log("GET Masked LOI DATA:", data);
		downloadJsonData(data.data, "getLoiMaskedDataConfig");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching loi masked data config:', error);
		throw new Error('Failed to update loi masked data config.');
	}
}

export async function loadLoiImportOptions(projectId) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('getLoiImportOptions.json');
		}
		console.log("LOI Import project id!", projectId);

		const loiImportOptionsEndPoint = endpoints.loi.loiImportOptions(projectId);
		let data = await springboardApiRequest(loiImportOptionsEndPoint, "GET");
		console.log("LOI import options data:", data);
		downloadJsonData(data.data, "getLoiImportOptions");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching loi masked data config:', error);
		throw new Error('Failed to update loi masked data config.');
	}
}

export async function updateLOIData(loiCellData, loiName, progressCallback) {
	console.log("UPDATING LOI DATA!");
	console.log("CELL DATA: ", loiCellData);
	if (DEV_TEST_MODE) {
		//await simulateServerDelay(DEV_SIM_POST_SERVER_RESPONSE_TIME);
		return bulkUpdateLoiCellAdaptor(loiCellData);
	}

	const chunks = chunkArray(loiCellData, 500);

	const loiBulkEditEndpoint = endpoints.loi.bulkEdit();
	const aggregatedData = { data: [], deletes: [], errors: [] };
	let currentChunk = 0;
	const totalChunks = chunks.length;
	for (const chunk of chunks) {
		// Prepare the request payload for the current chunk
		let bulkAddreq = {
			LOIName: loiName,
			Data: chunk
		};

		// Make the API request for the current chunk
		try {
			currentChunk++;
			let response = await springboardApiRequest(loiBulkEditEndpoint, "POST", bulkAddreq);
			let bulkAddResponse = response.data;
			console.log("LOI server DATA:", bulkAddResponse);

			// Aggregate the data, deletes, and errors
			if (bulkAddResponse.data) aggregatedData.data.push(...bulkAddResponse.data);
			if (bulkAddResponse.deletes) aggregatedData.deletes.push(...bulkAddResponse.deletes);
			if (bulkAddResponse.errors) aggregatedData.errors.push(...bulkAddResponse.errors);

			// Update request on progress
			const completedChunksProgress = (currentChunk - 1) / totalChunks * 100;
			if (progressCallback !== null) {
				progressCallback(completedChunksProgress);
			}
		} catch (error) {
			console.error("Error processing loi data chunk");
			console.error('Error while updating loi data:', error);
			throw new Error('Failed to update loi data.');
		}
	}

	return aggregatedData;
}

export async function updateAttrConfigData(attrData, loiName) {

	try {
		console.log("UPDATING ATTR DATA!");
		console.log("ATTR DATA: ", attrData);
		if (DEV_TEST_MODE) {
			return attrData;
		}

		let attrUpdateData = {
			LOIName: loiName,
			attributes: [attrData]
		};

		const attrUpdateEndpoint = endpoints.loi.attributeConfigUpdate();
		let data = await springboardApiRequest(attrUpdateEndpoint, "POST", attrUpdateData);
		console.log("ATTR server DATA:", data);
		return data.data;
		// Made up of:
		// data: array
		// deletes: array ? not sure what this contains yet
		// errors:
	} catch (error) {
		// Handle error here
		console.error('Error while updating loi data:', error);
		throw new Error('Failed to update loi data.');
	}
}

export async function updateAttrOrder(columnData, loiName) {

	try {
		console.log("UPDATING ATTR ORDER!");
		console.log("ATTR ORDER: ", columnData);
		if (DEV_TEST_MODE) {
			return columnData;
		}

		let attrOrderUpdateData = {
			LOIName: loiName,
			columns: columnData
		};

		const attrOrderUpdateEndpoint = endpoints.loi.updateAttributeOrder();
		let data = await springboardApiRequest(attrOrderUpdateEndpoint, "POST", attrOrderUpdateData);
		console.log("ATTR Order server DATA:", data);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while updating attr order data:', error);
		throw new Error('Failed to update attr order data.');
	}
}



export async function importLOI(importLoiData) {

	try {
		console.log("IMPORT LOI");
		console.log("Import LOI DATA: ", importLoiData);
		if (DEV_TEST_MODE) {
			return getFakeQueueIdObj();
		}

		let data = await springboardApiRequest(endpoints.loi.importLOI(), "POST", importLoiData);
		console.log("import loi server DATA:", data);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while setting import loi data:', error);
		throw new Error('Failed to import loi');
	}
}

export async function downloadLOI(loiName) {

	try {
		console.log("DOWNLOAD LOI");
		console.log("LOI Name: ", loiName);
		if (DEV_TEST_MODE) {
			return getFakeQueueIdObj();
		}

		let downloadLoiData = {
			LoiName: loiName,
			SiteId: getSiteId()
		};

		let data = await springboardApiRequest(endpoints.loi.downloadLOI(), "POST", downloadLoiData);
		console.log("download loi server DATA:", data);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while setting import loi data:', error);
		throw new Error('Failed to import loi');
	}
}

export async function deleteLOI(loiName) {

	try {
		console.log("DELETE LOI");
		console.log("LOI Name: ", loiName);
		if (DEV_TEST_MODE) {
			return true;
		}

		const deleteLoiEndpoint = endpoints.loi.deleteLOI(loiName);

		let data = await springboardApiRequest(deleteLoiEndpoint, "POST", "");
		console.log("download loi server DATA:", data);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while setting import loi data:', error);
		throw new Error('Failed to import loi');
	}
}

export async function updateScheduleToMatchLOI(loiName) {

	try {
		console.log("UPDATE LOI SCHEDULE");
		console.log("LOI Name: ", loiName);
		if (DEV_TEST_MODE) {
			return getFakeQueueIdObj();
		}

		let loiScheduleData = {
			loiName: loiName
		};
		const updateScheduleLoiEndpoint = endpoints.loi.updateSchedule();
		let data = await springboardApiRequest(updateScheduleLoiEndpoint, "POST", loiScheduleData);
		console.log("Update loi schedule server DATA:", data);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while setting updating loi schedule data:', error);
		throw new Error('Failed to update loi schedule');
	}
}

export async function copyLOI(copyLoiData) {

	try {
		console.log("COPY LOI FROM ANOTHER SITE");
		console.log("LOI Name: ", copyLoiData);
		if (DEV_TEST_MODE) {
			return getFakeQueueIdObj();
		}

		const copyLOIendpoint = endpoints.loi.copyLOI();

		let data = await springboardApiRequest(copyLOIendpoint, "POST", copyLoiData);
		console.log("COPY loi server DATA:", data);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while copying loi data:', error);
		throw new Error('Failed to copy loi');
	}
}