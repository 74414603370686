import React, { useEffect, useState } from 'react';
import {
	Table, TableContainer, TableFooter, TablePagination,
} from '@mui/material';

const PaginatedTable = ({ data, rowsPerPageDefault = 10, children }) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageDefault);
	const [prevCount, setPrevCount] = useState(data.length);

	useEffect(() => {
		if (prevCount !== data.length) {
			setPage(0);
			setPrevCount(data.length);
		}
	}, [data, prevCount]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		const value = event.target.value === 'All' ? data.length : parseInt(event.target.value, 10);
		setRowsPerPage(value);
		setPage(0);
	};
	//console.log("PAGE DATA:", data);
	const displayedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	return (
		<TableContainer>
			<Table size="small">
				{children(displayedData)}
				<TableFooter>
					<TablePagination
						showFirstButton
						showLastButton
						count={data.length}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: 'All' }]}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</TableFooter>
			</Table>
		</TableContainer>
	);
};

export default PaginatedTable;
