import React from 'react';
import { CssBaseline, Container, ThemeProvider, createTheme } from '@mui/material';
import {
	AdminsPage,
	ClassificationsPage,
	DocumentsPage,
	ErrorPage,
	FileCategoriesPage,
	HomePage,
	LoadingPage,
	LevelsOfInformationPage,
	LocationsPage,
	ProjectsPage,
	SitePage,
	SiteSecurityPage,
	SharedLinksManagerPage,
	TransactionLogPage,
	UserLookupPage,
	UsersPage,
	UserRoleChangesPage,
	UserSiteManagePage,
	UnauthorisedPage,
	WorkflowResponsibilityPage,
	WorkflowsPage
} from './components/pages';
import './App.css';
import { setSiteId, setAPIKey, setBaseURL } from './api/configManager';
import MainNavigationBar from './components/MainNavigationBar';
import ApiDbProvider from './providers/ApiDbProvider';

const theme = createTheme({
	typography: {
		caption: {
			fontFamily: 'Roboto',
			fontSize: '12px',
			fontWeight: 400,
			letterSpacing: '0.4px',
			//color: '#000000DE'
		},
		body1: {
			fontFamily: 'Roboto',
			fontSize: '16px',
			fontWeight: 400,
			lineHeight: '24px',
			letterSpacing: '0.15px',
			textAlign: 'left',
			//color: "#00000099",
		},
		h4: {
			fontFamily: 'Roboto',
			fontSize: '24px',
			fontWeight: 400,
			lineHeight: '42px',
			letterSpacing: '0.25px',
			textAlign: 'left',
		},
		h5: {
			fontFamily: 'Roboto',
			fontSize: '18px',
			fontWeight: 400,
			lineHeight: '32px',
			letterSpacing: '0px',
			textAlign: 'left',
		},
		h6: {
			fontFamily: 'Roboto',
			fontSize: '14px',
			fontWeight: 500,
			lineHeight: '32px',
			letterSpacing: '0.15px', // Rounded from 0.15000000596046448px
			textAlign: 'left',
		},
	},
	palette: {
		info: {
			main: '#03A9F4',
			contrastText: '#ffffff'
		},
		primary: {
			main: '#673AB7',
		},
		background: {
			default: "#ffffff",
			paper: 'white'
		}
	}
});

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			page: 'home',
			siteId: null,
			authToken: null,
			error: null
		};
	}

	componentDidMount() {
		this.setupParentMessageReceiver();
		this.loadURLQueryParameters();

		// If after 5 seconds authToken is not set, show an error.
		setTimeout(() => {
			if (!this.state.authToken) {
				this.raiseError("Timeout waiting for auth token.");
			}
		}, 5000);
	}

	setupParentMessageReceiver() {
		window.addEventListener('message', this.receiveMessage, false);

		// Try to send a message to the parent if it exists
		if (window.parent && window.parent !== window) {
			console.log("Sending post message to parent");
			window.parent.postMessage('window.loaded', '*');
		}
		// If window.parent is not available or is the same as the current window, use window.opener
		else if (window.opener) {
			console.log("Sending post message to opener");
			window.opener.postMessage('window.loaded', '*');
		}
		else {
			this.raiseError("Error: This app is intended to be operated within an iFrame or opened as a child window!");
		}
	}

	loadURLQueryParameters() {
		// Load query parameters into apps
		try {
			const params = new URLSearchParams(window.location.search);
			const siteId = params.get('S');

			this.setState({ siteId });
			setSiteId(siteId);

			if (!siteId) {
				throw new Error("siteId must always be present.");
			}

			const baseURL = params.get('U');
			console.log("Base URL set: ", baseURL);
			setBaseURL(baseURL);

		}
		catch (error) {
			this.raiseError(error.message);
		}
	}

	raiseError = (error) => {
		this.setState({ error });
	}
	// Used to receive message from parent iFrame.
	receiveMessage = (event) => {
		if (event.data.message === 'Auth token' && event.data.token) {
			setAPIKey(event.data.token);
			this.setState({ authToken: event.data.token });
			console.log("Token received: ");
			console.log(event.data.token);
		}
	}

	navigateTo = (pageName) => {
		this.setState({ page: pageName });
	}

	render() {
		const { page,
			error,
			siteId,
			authToken
		} = this.state;

		if (!authToken || !siteId) {
			if (error) {
				return (<ErrorPage error={error} />);
			}
			return <LoadingPage />;
		}

		return (
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<ApiDbProvider>
					<MainNavigationBar navigateTo={this.navigateTo}>
						<Container maxWidth={false} style={{ backgroundColor: theme.palette.background.paper }}>
							{page === 'home' && <HomePage navigateTo={this.navigateTo} />}
							{page === 'users' && <UsersPage
								navigateTo={this.navigateTo}
							/>}
							{page === 'UserRoleChangesPage' && <UserRoleChangesPage />}
							{page === 'SitePage' && <SitePage />}
							{page === 'SiteSecurityPage' && <SiteSecurityPage />}

							{page === 'Projects' && <ProjectsPage
								navigateTo={this.navigateTo}
							/>}
							{page === 'Documents' && <DocumentsPage
								navigateTo={this.navigateTo}
							/>}
							{page === 'Workflows' && <WorkflowsPage
								navigateTo={this.navigateTo}
							/>}
							{page === 'UserSiteManage' && <UserSiteManagePage
								navigateTo={this.navigateTo}
							/>}
							{page === 'workflowResponsibility' && <WorkflowResponsibilityPage
								navigateTo={this.navigateTo}
							/>}
							{page === 'LocationsPage' && <LocationsPage />}
							{page === 'LevelsOfInformationPage' && <LevelsOfInformationPage />}
							{page === 'FileCategories' && <FileCategoriesPage />}
							{page === 'SharedLinksManagerPage' && <SharedLinksManagerPage />}
							{page === 'TransactionLogPage' && <TransactionLogPage />}
							{page === 'admins' && <UnauthorisedPage />}
							{page === 'Administrators' && <AdminsPage />}
							{page === 'ClassificationsPage' && <ClassificationsPage />}
							{page === 'UserLookupPage' && <UserLookupPage />}
							{page === 'NotAdded' && <UnauthorisedPage />}
						</Container>
					</MainNavigationBar>
				</ApiDbProvider>
			</ThemeProvider>
		);
	}
}

export default App;
